<template>
	<div class="xinWenZhongXin" id="xinWenZhongXin">
		<div class="bg"></div>
		<div class="nav-list">
			<div class="wrap" id="scrollIntoView" :style="{ paddingTop: getHeaderHeight, marginTop: `-${getHeaderHeight}` }">
				<div
					class="item"
					:class="erJiMenu_path == item.name ? 'active' : ''"
					v-for="(item, index) in menuItem_xinWenZhongXin.children"
					:key="index"
					@click="menuItem_xinWenZhongXin_item_click(item, index)"
				>
					{{ item.name }}
				</div>
			</div>
		</div>
		<div class="news-list">
			<div class="wrap">
				<div class="item" v-for="(item, index) in xinWenZhongXin" :key="index" @click="$router.push({ name: 'newsDetail', params: { id: item.id } })">
					<div class="date-box" v-if="!isMobile">
						<div class="day">{{ getDate(item.createTime).split('-')[2] }}</div>
						<div class="year-month">
							{{
								getDate(item.createTime)
									.split('-')
									.slice(0, 2)
									.join('.')
							}}
						</div>
					</div>
					<div class="content">
						<div class="date" v-if="isMobile">
							{{
								getDate(item.createTime)
									.split('-')
									.join('.')
							}}
						</div>
						<div class="title">{{ item.contentTitle }}</div>
						<div class="description">{{ item.contentDescription }}</div>
						<div class="pic-box"><h-img class="pic-027" :src="item.contentImg"></h-img></div>
					</div>
				</div>
			</div>
		</div>
		<div class="paging-device" v-if="!isMobile">
			<el-pagination
				background
				layout="prev, pager, next"
				prev-text="上一页"
				next-text="下一页"
				:total="total"
				:page-size="pageSize"
				:current-page.sync="currentPage"
				@current-change="currentChange"
			></el-pagination>
		</div>
	</div>
</template>

<script>
import { ContentPage } from '@/apis/wenZhangLanMu.js';
export default {
	data() {
		return {
			menuItem_xinWenZhongXin: {},
			xinWenZhongXin: [],
			currentPage: 1,
			total: 0,
			pageSize: 0
		};
	},
	watch: {
		erJiMenu_path: {
			handler(val, oldval) {
				this.menuItem_xinWenZhongXin = { ...this.$store.getters.getMenuItem('新闻中心') };
				if (!val) {
					this.$store.commit('changeErJiMenuPath', this.menuItem_xinWenZhongXin.children[0].name);
				} else if (this.menuItem_xinWenZhongXin.children.some(item => val == item.name)) {
					this.$nextTick(() => {
						this.currentPage = 1;
						this.getContentPageForXinWenZhongXin();
					});
				}
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		if (this.isMobile) {
			this.$bus.$on('reachBottom', data => {
				if (this.xinWenZhongXin.length && this.currentPage * this.pageSize < this.total) {
					this.currentPage++;
					this.getContentPageForXinWenZhongXin();
				} else {
					this.$message({
						message: '没有更多了~',
						center: true,
						offset: window.screen.height / 2
					});
				}
			});
		}
	},
	beforeDestroy() {
		this.$bus.$off('reachBottom');
	},
	methods: {
		getContentPageForXinWenZhongXin() {
			let menuId = -1;
			if (this.erJiMenu_path == '签约动态') {
				menuId = 2058;
			} else if (this.erJiMenu_path == '公司动态') {
				menuId = 2059;
			} else if (this.erJiMenu_path == '银联新闻') {
				menuId = 2085;
			}
			this.pageSize = this.isMobile ? 10 : 6;
			ContentPage(menuId, '', this.currentPage, this.pageSize).then(res => {
				this.total = res.total;
				if (!this.isMobile) {
					this.xinWenZhongXin = res.data;
				} else {
					this.xinWenZhongXin = this.xinWenZhongXin.concat(res.data);
				}
			});
		},
		menuItem_xinWenZhongXin_item_click(item, index) {
			if (this.erJiMenu_path == item.name) {
				return;
			} else {
				this.$store.commit('changeErJiMenuPath', item.name);
			}
		},
		currentChange(e) {
			this.currentPage = e;
			this.getContentPageForXinWenZhongXin();
			document.querySelector('#scrollIntoView').scrollIntoView({
				behavior: 'smooth'
			});
		}
	}
};
</script>

<style lang="scss" scoped>
@import 'xinWenZhongXin.scss';
@import 'xinWenZhongXinMedia.scss';
</style>
